<template>
  <div class="event px8" style="padding-top: 40px;">
    <div class="flex">
      <a
        class="pointer"
        @click="path.includes('/arcause') ? $router.push('/arcause') : $router.push('/events')"
        style="padding-left: calc(6% + 40px);"
        >
        <i class="arrow left icon"></i>
        <span class="bold ml12 hover-black black">Back to Events</span>
      </a>
      <div v-if="roles" class="mlauto mr80 inline-flex items-center grow">
        <button @click="duplicateEvent" class="black relative mlauto">
          Duplicate
          <div v-if="duplicationInProgress" class="ui active loader"></div>
        </button>
        <p class="ml20" v-if="duplicationSuccess">Event copy created.</p>
      </div>
    </div>
    <div v-if="authorized">
      <campaign
        v-if="active && !old"
        :campaign="active"
        :showImages="true"
      ></campaign>
      <old-campaign
        v-if="active && old"
        :campaign="active"
        :showImages="true"
      />
      <!-- <div v-if="hasSidebar">
        <div v-for="section in active.sidebarSections">
          <div class="br4">
          </div>
          <div class="py-8" v-for="item in section.items">
            <span>{{section.label}}</span>
            <span>{{section.value}}</span>
          </div>
        </div>
      </div> -->
    </div>
    <div
      v-if="authorized === false"
      class="flex vertical centered items-center h-screen"
    >
      <h3 style="position: relative; bottom: 100px;">
        This event is for specific emails only.
      </h3>
      <p style="position: relative; bottom: 100px;">
        You are not allowed to view this event.
      </p>
    </div>
  </div>
</template>

<style>
.text-big {
  font-size: 1.4em;
}
</style>

<script>
import campaign from "../components/campaign";
import oldCampaign from "../components/old-campaign";
export default {
  name: "Event",
  components: { campaign, oldCampaign },
  // metaInfo() {

  //   const meta = {}

  //   return {
  //     title: this.active.Title,
  //     meta: [
  //       { charset: "utf-8" },
  //       { name: "viewport", content: "width=device-width, initial-scale=1" },
  //       {
  //         hid: "description",
  //         name: "description",
  //         content: this.active.description
  //       },
  //       {
  //         hid: "keywords",
  //         name: "keywords",
  //         content: ""
  //       }
  //     ]
  //   }
    
  // },
  data() {
    return {
      loading: false,
      active: "",
      authorized: null,
      duplicationInProgress: false,
      duplicationSuccess: false,
      old: false,
      roles: null,
      path: this.$route?.fullPath || ""
    };
  },
  mounted() {
    /* eslint-disable-next-line no-undef */
    $(document).scrollTop(0);
    let name = this.$route.params.name;
    this.$bus
      .getLatestRoles()
      .then(r => {
        this.roles = r;
      })
      .catch(e => console.log(e));
    
    const path = this.$route.fullPath || ""
    if (path.includes("/ideace/events/") || path.includes('/arcause')) {
      this.$req
        .get(`events?pageName=${name}`)
        .then(r => {
          let evt = r.data[0];
          this.checkAuthorized(evt);
          this.active = evt;
        })
        .catch(e => console.log(e));
    } else {
      this.$req
        .get(`events?Title=${name}`)
        .then(r => {
          let ev = r.data[0];
          this.checkAuthorized(ev);
          if (!ev) {
            this.$req
              .get(`old-events?name=${name}`, this.$bus.headers)
              .then(r => {
                this.active = r.data[0];
                if (this.active) {
                  this.old = true;
                }
              })
              .catch(e => console.log(e));
            return;
          }
          this.active = r.data[0];
        })
        .catch(e => console.log(e));
    }
  },
  methods: {
    checkAuthorized: function(evt) {
      if (
        !evt?.authorizedParticipants ||
        evt?.authorizedParticipants?.length == 0
      ) {
        this.authorized = true;
      } else if (
        evt?.authorizedParticipants &&
        evt?.authorizedParticipants?.length > 0
      ) {
        let email = this.$bus?.user?.email;
        this.authorized = evt.authorizedParticipants
          .map(e => e.email)
          .includes(email);
      } else {
        this.authorized = false;
      }
    },
    duplicateEvent: function() {
      let active = this.active;
      this.duplicationInProgress = true;
      let copy = {
        Title: active.Title + " copy",
        master_event: active.master_event,
        Information: active.Information,
        requiredTeamMembers: active.requiredTeamMembers,
        type: active.type,
        videoUrl: active.videoUrl,
        ParticipantField: active.ParticipantField,
        ParticipantType: active.ParticipantType,
        hide: true,
        sameRegionTeam: active.sameRegionTeam,
        sections: active.sections,
        grading: active.grading,
        ClassifyByRegion: active.ClassifyByRegion,
        teamMembers: active.teamMembers
      };
      this.$req
        .post(`events`, copy, this.$bus.headers)
        .then(() => {
          this.duplicationInProgress = false;
          this.duplicationSuccess = true;
        })
        .catch(() => {
          this.duplicationInProgress = false;
          alert("An error occured");
        });
    }
  }
};
</script>
